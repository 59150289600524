<template>
    <div>
        <alert-popup
            v-if="editSuccess"
            alert-type="success"
            alert-message="Project edited!"
        ></alert-popup>
        <alert-popup
            v-if="deleteSuccess"
            alert-type="success"
            alert-message="Project deleted!"
        ></alert-popup>

        <v-container ma-0 pa-0 grid-list-md text-xs-center fill-height>
            <v-layout row wrap>
                <label>Project Name *</label>
                <v-flex xs12 sm6 md12 v-if="permissions === 'admin'">
                    <v-text-field
                        placeholder="Project will appear as this."
                        solo
                        v-model="name"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md12 v-if="permissions === 'manager'">
                    <v-text-field
                        placeholder="Project will appear as this."
                        solo
                        disabled
                        v-model="name"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.name" class="text-danger">
                    {{ errors.name }}
                </div>

                <label>Project Code *</label>
                <v-flex xs12 sm6 md12 v-if="permissions === 'admin'">
                    <v-text-field
                        placeholder="Assign a code."
                        solo
                        v-model="code"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md12 v-if="permissions === 'manager'">
                    <v-text-field
                        placeholder="Assign a code."
                        solo
                        disabled
                        v-model="code"
                    ></v-text-field>
                </v-flex>
                <div v-if="errors && errors.code" class="text-danger">
                    {{ errors.code }}
                </div>

                <label>Project Description</label>
                <v-flex xs6 md12>
                    <v-textarea
                        solo
                        name="input-7-4"
                        placeholder="Describe the project"
                        v-model="description"
                    ></v-textarea>
                </v-flex>

                <button
                    class="btn btn-block btn-primary"
                    @click="saveProjectEdits()"
                >
                    Save Edits
                </button>
                <button
                    class="btn btn-block btn-danger"
                    v-if="permissions === 'admin'"
                    @click="deleteProject()"
                >
                    Delete Project
                </button>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: ["projectId", "locationSlug", "permissions"],
    data() {
        return {
            name: null,
            code: null,
            description: null,
            errors: {},
            editSuccess: false,
            deleteSuccess: false,
            loaded: true,
            csrfToken: null,
        };
    },
    mounted() {
        this.getProjectDetails();

        this.csrfToken = document.querySelector(
            'meta[name="csrf-token"]'
        ).content;
    },
    methods: {
        getProjectDetails() {
            axios
                .post("/getProjectDetails", {
                    csrfToken: this.csrfToken,
                    projectId: this.projectId,
                })
                .then((response) => {
                    this.loaded = true;
                    this.name = response.data.name;
                    this.code = response.data.code;
                    this.description = response.data.description;
                })
                .catch((error) => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
        },
        saveProjectEdits() {
            if (this.loaded) {
                this.loaded = false;
                this.editSuccess = false;
                this.errors = [];
                if (!this.name) {
                    this.errors.name = "Name required.";
                }
                if (!this.code) {
                    this.errors.code = "Project code required.";
                }

                axios
                    .post("/editProject", {
                        csrfToken: this.csrfToken,
                        projectId: this.projectId,
                        name: this.name,
                        code: this.code,
                        description: this.description,
                    })
                    .then((response) => {
                        this.fields = {}; //Clear input fields.
                        this.loaded = true;
                        this.editSuccess = true;
                    })
                    .catch((error) => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
            }
        },
        deleteProject() {
            if (confirm("Are you sure you want to delete this project?")) {
                axios
                    .post("/deleteProject", {
                        csrfToken: this.csrfToken,
                        projectId: this.projectId,
                        name: this.name,
                        code: this.code,
                        description: this.description,
                    })
                    .then((response) => {
                        this.deleteSuccess = true;
                        if (this.locationSlug) {
                            window.location =
                                "/l/" + this.locationSlug + "/projects";
                        } else {
                            window.location = "/projects";
                        }
                    })
                    .catch((error) => {
                        this.loaded = true;
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }
                    });
            }
        },
    },
};
</script>
