<template>
    <div>
        <h1>Students with Missed Classes</h1>

        <!-- Search Bar -->
        <div>
            <input
                v-model="searchQuery"
                type="text"
                placeholder="Search by student name"
                class="student-search"
                @input="fetchStudents"
            />
        </div>

        <p>viewing {{ students.length }}</p>
        <!-- Students Table -->
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <!-- <th>Parent Notes</th> -->
                    <th>Attendance Records</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="student in students" :key="student.id">
                    <td>{{ student.galore_id }}</td>
                    <td>{{ student.first_name }} {{ student.last_name }}</td>
                    <td>{{ student.last_name }}</td>
                    <!-- <td>{{ student.parent_notes }}</td> -->
                    <td>
                        <ul>
                            <li
                                v-for="record in student.attendance_records"
                                :key="record.id"
                            >
                                {{ record.formatted_start_time }} -
                                {{ record.formatted_end_time }}: Status
                                {{ record.term_class_period }}
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- Pagination Controls -->
        <div v-if="pagination.total > 1" class="pagination">
            <button
                :disabled="pagination.currentPage === 1"
                @click="fetchStudents(pagination.currentPage - 1)"
            >
                Previous
            </button>
            <span
                >Page {{ pagination.currentPage }} of
                {{ pagination.lastPage }}</span
            >
            <button
                :disabled="pagination.currentPage === pagination.lastPage"
                @click="fetchStudents(pagination.currentPage + 1)"
            >
                Next
            </button>
        </div>
        <pre>
            students
            {{ students }}
        </pre>
    </div>
</template>

<script>
export default {
    data() {
        return {
            students: [],
            pagination: {
                currentPage: 1,
                lastPage: 1,
                total: 0,
            },
            searchQuery: "",
        };
    },
    methods: {
        async fetchStudents(page = 1) {
            try {
                const response = await axios.post("/viewStudentMissedClasses", {
                    search: this.searchQuery,
                    page,
                });

                if (response.data.success) {
                    const { data, current_page, last_page, total } =
                        response.data.students;

                    this.students = data;
                    console.log("students ", this.students);
                    this.pagination = {
                        currentPage: current_page,
                        lastPage: last_page,
                        total,
                    };
                } else {
                    console.error(
                        "Failed to fetch students:",
                        response.data.msg
                    );
                }
            } catch (error) {
                console.error(
                    "An error occurred while fetching students:",
                    error
                );
            }
        },
    },
    mounted() {
        this.fetchStudents();
    },
};
</script>

<style>
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

.pagination {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
}

button {
    padding: 5px 10px;
}

input {
    margin: 20px 0;
    padding: 8px;
    width: 100%;
}
.student-search {
    background: white;
    border: 2px solid #bebebe;
}
</style>
