<template>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="alertType"
        :bottom="true"
        :right="true"
    >
        {{ alertMessage }}
        <v-btn dark text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        alertType: {
            type: String,
            default: "success",
        },
        alertMessage: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            snackbar: true,
            timeout: 3000,
        };
    },
};
</script>

<style>
.alert-popup {
    padding: 1em;
    border-radius: 4px;
    color: white;
}
.v-snack__wrapper.theme--dark.success {
    color: white;
    background-color: #38c172;
}
</style>
