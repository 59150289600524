<template>
    <div>
        <div>
            <v-flex xs12 sm6 m6>
                <v-text-field
                    placeholder="Number of Days to look back"
                    v-model="lookBackDays"
                    type="number"
                ></v-text-field>
            </v-flex>
        </div>
        <table
            border="1"
            class="missed-classes-table"
            v-if="missedClasses.length > 0"
        >
            <thead>
                <tr>
                    <th>Parent Name</th>
                    <th>Student Name</th>
                    <th>Email</th>
                    <th>Class Date</th>
                    <th>Class Name</th>
                    <th>Class Time</th>
                    <th>Course End</th>
                    <th>Studio Email</th>
                    <th>Studio Phone</th>
                    <th>Reschedule Link</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in missedClasses" :key="index">
                    <td>{{ item.parentName }}</td>
                    <td>
                        {{ item.studentFirstName }} {{ item.studentLastName }}
                    </td>
                    <td>{{ item.email }}</td>
                    <td>{{ formatDate(item.classDate) }}</td>
                    <td>{{ item.className }}</td>
                    <td>{{ item.classTime }}</td>
                    <td>{{ formatDate(item.courseEnd) }}</td>
                    <td>{{ item.studioEmail }}</td>
                    <td>{{ item.studioPhone }}</td>
                    <td>
                        <a :href="item.reschedule_link" target="_blank"
                            >Reschedule</a
                        >
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-else>
            <p>No missed classes found.</p>
        </div>

        <pre>
            missedClasses
            {{ missedClasses }}
        </pre>
    </div>
</template>

<script>
export default {
    data() {
        return {
            missedClasses: [],
            lookBackDays: 4,
            message: "",
            success: false,
            loaded: false,
            csrfToken: null,
        };
    },
    methods: {
        async fetchMissedClasses() {
            try {
                const response = await axios.post(
                    "/checkMultipleDaysMissedClasses",
                    {
                        lookBackDays: this.lookBackDays,
                        returnData: true,
                    }
                );

                if (response.data.success) {
                    this.missedClasses = response.data;
                    console.log("fetchMissedClasses", response.data);
                } else {
                    console.error(
                        "Failed to fetch students:",
                        response.data.msg
                    );
                }
            } catch (error) {
                console.error(
                    "An error occurred while fetching students:",
                    error
                );
            }
        },
    },
    mounted() {
        this.fetchStudents();
    },
};
</script>

<style>
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

.pagination {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
}

button {
    padding: 5px 10px;
}

input {
    margin: 20px 0;
    padding: 8px;
    width: 100%;
}
.student-search {
    background: white;
    border: 2px solid #bebebe;
}
</style>
